import { environment } from '@src/environments/environment';
import { SidenavParams } from '@zonar-ui/sidenav/lib/sidenav/interfaces';

export const SidenavHeaderConfig = {
  displayName: true, // if we want to display name of the user
  avatarImageUrl: null, // if we want to show a specific avatar picture e.g. user's avatar
  optionalSubtitle: null
};

export const SidenavMenuConfig = [
  {
    text: 'Performance',
    icon: 'area_chart',
    appId: environment.auth.applicationId,
    route: '/',
    routeLevel: 1,
    isExpanded: false,
    children: [
      {
        text: 'Usage',
        icon: '',
        route: 'usage',
        routerLink: '/usage',
        isExpanded: false,
        routeLevel: 2,
        children: []
      },
      {
        text: 'Idle',
        icon: '',
        route: 'idle',
        routerLink: '/idle',
        isExpanded: false,
        routeLevel: 2,
        children: []
      }
    ]
  }
];

export const SidenavFooterConfig = {
  items: []
};

export const SidenavGlobalConfig: SidenavParams = {
  mobileOpened: true,
  expanded: true,
  footerOffset: false
};

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { OverviewContainerComponent } from './containers/overview-container/overview-container.component';

const routes: Routes = [
  {
    path: 'usage',
    component: OverviewContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'asset',
        pathMatch: 'full'
      },
      {
        path: 'asset',
        loadComponent: () =>
          import('./containers/usage-container/usage-container.component').then(
            (m) => m.UsageContainerComponent
          ),
        data: { label: 'assetView', assetType: 'usage' },
        canActivate: [AuthGuard]
      },
      {
        path: 'event',
        loadComponent: () =>
          import(
            './containers/usage-event-container/usage-event-container.component'
          ).then((m) => m.UsageEventContainerComponent),
        data: { label: 'eventView', assetType: 'usage' },
        canActivate: [AuthGuard]
      }
    ],
    data: { includeSettingsLink: true }
  },
  {
    path: 'idle',
    component: OverviewContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'asset',
        pathMatch: 'full'
      },
      {
        path: 'asset',
        loadComponent: () =>
          import('./containers/idle-container/idle-container.component').then(
            (m) => m.IdleContainerComponent
          ),
        data: { label: 'assetView', assetType: 'idle' },
        canActivate: [AuthGuard]
      },
      {
        path: 'event',
        loadComponent: () =>
          import(
            './containers/idle-event-container/idle-event-container.component'
          ).then((m) => m.IdleEventContainerComponent),
        data: { label: 'eventView', assetType: 'idle' },
        canActivate: [AuthGuard]
      }
    ],
    data: { includeSettingsLink: true },
    canActivate: [AuthGuard]
  },
  {
    path: 'utilization',
    component: OverviewContainerComponent,
    children: [
      {
        path: '',
        redirectTo: 'hours',
        pathMatch: 'full'
      },
      {
        path: 'hours',
        loadComponent: () =>
          import(
            './containers/utilization-container/utilization-container.component'
          ).then((m) => m.UtilizationContainerComponent),
        data: { label: 'hours', assetType: 'utilization' },
        canActivate: [AuthGuard]
      },
      {
        path: 'miles',
        loadComponent: () =>
          import(
            './containers/utilization-container/utilization-container.component'
          ).then((m) => m.UtilizationContainerComponent),
        data: { label: 'miles', assetType: 'utilization' },
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: 'unused_asset',
    loadComponent: () =>
      import(
        './containers/unused-assets-container/unused-assets-container.component'
      ).then((m) => m.UnusedAssetsContainerComponent),
    data: { label: 'eventView', assetType: 'usage' },
    canActivate: [AuthGuard]
  },
  {
    path: '',
    redirectTo: 'usage',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

<div class="overview-container primary-container">
  <div
    class="title-container"
    fxLayout.gt-sm="row"
    fxLayoutAlign.gt-sm="space-between center"
    fxLayout.lt-md="column"
    fxLayoutAlign.lt-md=" start">
    <app-page-title>
      {{ translateService.get(translations?.overview[pageTitle]) | async |
      translate }}
    </app-page-title>
    <app-settings *ngIf="isSettingsLinkDisplayed"></app-settings>
  </div>
  <nav mat-tab-nav-bar [tabPanel]="tabPanel">
    <a
      *ngFor="let link of navLinks"
      mat-tab-link
      #rla="routerLinkActive"
      [active]="activeLink === link"
      [routerLink]="link.path"
      queryParamsHandling="preserve"
      routerLinkActive>
      {{ translateService.get(translations.overview.matTabs[link.label]) | async
      | translate }}
    </a>
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</div>

import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@zonar-ui/i18n';
import { Translations } from '@app/services/i18n/translations.service';
import { ZonarUiIconModule } from '@zonar-ui/icon';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-settings',
  standalone: true,
  imports: [CommonModule, TranslateModule, ZonarUiIconModule],
  templateUrl: './settings.component.html'
})
export class SettingsComponent {
  constructor(
    public translateService: TranslateService,
    public translations: Translations
  ) {}
  public readonly settingUrl = environment.adminSettings.url;
}

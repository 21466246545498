import { Injectable } from '@angular/core';

import { Observable, BehaviorSubject, ReplaySubject } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { TranslateService } from '@zonar-ui/i18n';

import { Translations } from '@app/services/i18n/translations.service';
import { UserCompany } from '@app/models/user.company.model';
import { mockCompany } from '@src/mocks/user.company.mock';

@Injectable({
  providedIn: 'root'
})
export class SelectedCompanyService {
  private _userSelectedCompanyId$ = new ReplaySubject<string>(1);
  private _currentCompanyId$ = new BehaviorSubject<UserCompany>(mockCompany);

  constructor(
    public translateService: TranslateService,
    public translations: Translations
  ) {}

  public setCompanyIdFromSideNav(values: UserCompany) {
    this._currentCompanyId$.next(values);
  }

  public getCompanyFromSideNav(): Observable<UserCompany> {
    return this._currentCompanyId$.asObservable().pipe(
      filter((values) => values !== null && values.value !== mockCompany.value),
      map((values) => values)
    );
  }

  public getUserSelectedCompanyId() {
    return this._userSelectedCompanyId$.asObservable();
  }

  setUserCompany(companyId: string): void {
    this._userSelectedCompanyId$.next(companyId);
  }

  getUserCOmpany() {
    return this._userSelectedCompanyId$.asObservable();
  }

  getCompanyId() {
    return this._currentCompanyId$.getValue().value;
  }
}

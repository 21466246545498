import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { FaviconsService } from '@zonar-ui/core';
import { ZonarUiAnalyticsService } from '@zonar-ui/analytics';
import { TranslateLoader, TranslateModule } from '@zonar-ui/i18n';
import { ZonarUiIconModule } from '@zonar-ui/icon';
import { ZonarUiNotificationsModule } from '@zonar-ui/notifications';

import { environment } from '@environments/environment';
import { permissions } from '@environments/shared';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ZonarUiIconModule,
    ZonarUiNotificationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [ZonarUiIconModule, ZonarUiNotificationsModule],
  providers: [
    FaviconsService,
    ZonarUiAnalyticsService,

    // TODO: Build out Auth0 application in dev, QA, and Prod tenants.
    // TODO: Onboard to Core API for your new application to be able to build permissions.

    // Symbols required for authentication
    { provide: 'appUrl', useValue: environment.appUrl },
    { provide: 'audience', useValue: environment.auth.audience },
    { provide: 'clientID', useValue: environment.auth.clientID },
    { provide: 'domain', useValue: environment.auth.domain },
    { provide: 'applicationId', useValue: environment.auth.applicationId },
    { provide: 'environment', useValue: environment.authEnv },
    { provide: 'region', useValue: environment.region },
    {
      provide: 'useRefreshTokens',
      useValue: environment.auth.useRefreshTokens
    },

    // Symbols required for permissions
    { provide: 'defaultPermPrefix', useValue: permissions.defaultPermPrefix },
    {
      provide: 'defaultZonarRole',
      useValue: environment.auth.defaultZonarRole
    },
    { provide: 'routePermissions', useValue: permissions.routes },
    { provide: 'redirectUrl', useValue: permissions.defaultRedirectUrl },

    // Symbols required for internationalization
    {
      provide: 'supportedLanguages',
      useValue: environment.i18n.supportedLanguages
    },
    { provide: 'defaultLanguage', useValue: environment.i18n.defaultLanguage },
    { provide: 'pendoParams', useValue: environment.pendo }
  ]
})
export class CoreModule {}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ActivatedRoute,
  Router,
  Routes,
  RouterModule,
  NavigationEnd
} from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { CoreModule, FlexLayoutModule } from '@angular/flex-layout';

import { Subject } from 'rxjs';
import { filter, startWith, takeUntil, tap } from 'rxjs/operators';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

// zonar-ui pattern library
import { TranslateModule, TranslateService } from '@zonar-ui/i18n';

import { Translations } from '@app/services/i18n/translations.service';

// child components
import { PageTitleComponent } from '@app/components/page-title/page-title.component';
import { UsageContainerComponent } from '@app/containers/usage-container/usage-container.component';
import { IdleContainerComponent } from '@app/containers/idle-container/idle-container.component';
import { SettingsComponent } from './../../components/settings/settings.component';

@Component({
  selector: 'app-overview-container',
  standalone: true,
  imports: [
    FlexLayoutModule,
    CoreModule,
    CommonModule,
    RouterModule,
    MatCardModule,
    MatTabsModule,
    NgxSkeletonLoaderModule,
    PageTitleComponent,
    TranslateModule,
    UsageContainerComponent,
    IdleContainerComponent,
    SettingsComponent
  ],
  templateUrl: './overview-container.component.html',
  styleUrls: ['./overview-container.component.scss']
})
export class OverviewContainerComponent implements OnInit, OnDestroy {
  public cardData;
  public columns;
  public filterList;
  public pageTitle: string;

  navLinks = [];
  activeLink = null;

  isSettingsLinkDisplayed: boolean;

  private onDestroy$ = new Subject<void>();
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public translateService: TranslateService,
    public translations: Translations
  ) {}

  ngOnInit(): void {
    this.navLinks =
      !!this.route.routeConfig && !!this.route.routeConfig.children.length
        ? this.buildNavigatingItems(this.route.routeConfig.children)
        : [];
    this.activeLink = this.navLinks[0];
    this.isSettingsLinkDisplayed =
      !!this.route.snapshot?.data?.includeSettingsLink;
    this.subscribeForRouteChanges();
  }

  buildNavigatingItems(routes: Routes) {
    return routes
      .filter((route) => route.data)
      .map(({ path = '', data }) => ({
        path: path,
        label: data.label
      }));
  }

  subscribeForRouteChanges() {
    this.router.events
      .pipe(
        takeUntil(this.onDestroy$),
        filter((event) => event instanceof NavigationEnd),
        startWith(this.router), // let the component load the initial state from the injected Route, so that the event
        tap((event: NavigationEnd) => {
          let currentTabRoute = event.url.split('/').at(-1);
          this.pageTitle = event.url.split('/').at(-2);
          this.activeLink = this.navLinks.find((link) =>
            currentTabRoute.startsWith(link.path)
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
